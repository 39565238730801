import axios from 'axios'

// create an axios instance
const request = axios.create({
  baseURL: "/api", // url = base url + request url
  timeout: 15000 // request timeout
})

// request interceptor 请求拦截器
request.interceptors.request.use((config) => {
    return config;
})

// request interceptor 请求拦截器
request.interceptors.response.use((response) => {
    // 对响应数据做点什么
    return response;
})

export default request
