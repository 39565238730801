import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            list: [],
            banner: [],
            count: 0,
            page: 1,
            finished: false,

            info: {},
            comic_img: '',
            comic_img_last: '',
        };
    },
    mutations: {
        setList(state, payload) {
            state.list = payload.list;
            state.count = payload.count;
            state.page = payload.page;
        },
        setListFinish(state, payload) {
            state.finished = payload.finished;
        },
        setBanner(state, payload) {
            state.banner = payload.banner;
        },
        setInfo(state, payload) {
            state.info = payload.info;
            state.comic_img = payload.comic_img;
            state.comic_img_last = payload.comic_img_last;
        }
    },
    actions: {
        updateList({ commit }, payload) {
            commit('setList', payload);
        },
        updateListFinish({ commit }, payload) {
            commit('setListFinish', payload);
        },
        updateBanner({ commit }, payload) {
            commit('setBanner', payload);
        },
        updateInfo({ commit }, payload) {
            commit('setInfo', payload);
        }
    }
});

export default store;
