import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/:rd/:pixel_id',
        name: 'main_list',
        component: () => import('@/views/main_web/main_index'),
    },
    {
        path: '/:rd/:pixel_id/info/:id',
        name: 'main_info',
        component: () => import('@/views/main_web/main_index'),
    },
    {
        path: '/list/:rd/:pixel_id',
        name: 'comic_list',
        component: () => import('@/views/comic/comicList')
    },
    {
        path: '/info/:rd/:pixel_id/info/:id',
        name: 'comic_info',
        component: () => import('@/views/comic/comicInfo')
    },

    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        beforeEnter: () => {
            window.location.href = '/main_web/main_index_new';
        }
    },

    {
        path: '/',
        name: 'main_root',
        component: () => import('@/views/main_web/main_index_new'),
    },

    {
        path: '/app-:appid/:rd/:pixel_id',
        name: 'main_list_new',
        component: () => import('@/views/main_web/main_index_new'),
    },
    {
        path: '/app-:appid/:rd/:pixel_id/info/:id',
        name: 'main_info_new',
        component: () => import('@/views/main_web/main_index_new'),
    },
    {
        path: '/app-:appid/list/:rd/:pixel_id',
        name: 'comic_list_new',
        component: () => import('@/views/comic/comicListNew')
    },
    {
        path: '/app-:appid/info/:rd/:pixel_id/info/:id',
        name: 'comic_info_new',
        component: () => import('@/views/comic/comicInfoNew')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes
});

export default router;