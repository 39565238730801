import request from '@/utils/request'

export function adList(data) {
  return request({
    url: 'index.php?s=Ad/list',
    method: 'post',
    data,
  })
}

export function adListNew(data) {
  return request({
    url: 'index.php?s=Ad/list_new',
    method: 'post',
    data,
  })
}

export function adComicView(data) {
  return request({
    url: 'index.php?s=Ad/view',
    method: 'post',
    data
  })
}

export function reportAd(data){
  return request({
    url: 'index.php?s=Ad/report',
    method: 'post',
    data,
  })
}