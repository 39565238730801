import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import "amfe-flexible";
import 'vant/lib/index.css';
import { initFacebookPixel } from '@/utils/fb';
import store from './store'; // 引入 Vuex store

import { getAppid, getAppidNew } from '@/utils/common';  // 假设 getAppid 是获取 appid 的方法

function setFavicon(href) {
    let link = document.querySelector("link[rel*='icon']");
    if (!link) {
        link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = href;
}

function updateFavicon(appid = 0) {
    if( !appid ) {
        appid = getAppid();
    }else{
        appid = getAppidNew(appid)
    }
    let faviconPath = '/favicon1.ico'; // 默认 favicon
    if (appid == 2) {
        faviconPath = '/favicon2.ico';  // 条件匹配的 favicon
    }else if(appid == 3){
        faviconPath = '/favicon3.ico';  // 条件匹配的 favicon
    }
    setFavicon(faviconPath);
}


router.beforeEach((to, from, next) => {
    const pixelId = to.params.pixel_id;
    const appid = to.params.appid; // 从路由参数中获取 appid

    if (pixelId) {
        initFacebookPixel(pixelId);
    }

    if (appid) {
        updateFavicon(appid); // 更新 favicon
    }else{
        updateFavicon();
    }

    next();
});



import { Loading, Image as VanImage, Overlay, List, NavBar, Tabbar, PullRefresh, TabbarItem, Lazyload, Swipe, SwipeItem  } from 'vant';

const app = createApp(App);
const components = [ store, Loading, VanImage, Overlay, List, NavBar, Tabbar, PullRefresh, TabbarItem, Swipe, SwipeItem];

components.forEach(component => {
    app.use(component);
});

app.use(Lazyload, {
    lazyComponent: true,
});
app.use(router).mount('#app');
